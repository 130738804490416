import React, { Suspense, lazy } from 'react';
import { Navigate, Route, Routes, useParams } from 'react-router-dom';
import SLUGS from 'resources/slugs';
import LoadingComponent from 'components/loading';
import { convertSlugToUrl } from 'resources/utilities';


const DashboardComponent = lazy(() => import('./dashboard'));
const NewRequestComponent = lazy(() => import('./newRequest'));
const PerviousRequestsComponent = lazy(() => import('./perviousRequests'));
const RequestDetailsComponent = lazy(() => import('./requestDetails'));
const RequestFeedbackComponent = lazy(() => import('./requestFeedback'));
const ProfileComponent = lazy(() => import('./profile'));
const PaymentsComponent = lazy(() => import('./payments'));

function PrivateRoutes() {
    const { TenantName } = useParams();
    return (
        <Suspense fallback={<LoadingComponent loading />}>
            <Routes>
                <Route path={SLUGS.main} Component={DashboardComponent} />
                <Route exact path={SLUGS.residentsRequests} Component={DashboardComponent} />
                <Route path={SLUGS.residentsNewRequest} Component={NewRequestComponent} />
                <Route path={SLUGS.residentsPerviousRequests} Component={PerviousRequestsComponent} />
                <Route path={SLUGS.residentsRequestDetails} Component={RequestDetailsComponent} />
                <Route path={SLUGS.residentsRequestFeedback} Component={RequestFeedbackComponent} />
                <Route path={SLUGS.profile} Component={ProfileComponent} />
                <Route path={SLUGS.residentsPayments} Component={PaymentsComponent} />
                <Route path="*" element={<Navigate to={convertSlugToUrl(TenantName, SLUGS.main, {})} />} />
            </Routes>
        </Suspense>
    );
}

export default PrivateRoutes;
