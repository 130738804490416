export default {
    // private
    main: '/main',
    profile: '/profile',
    residentsRequests: '/residentsRequests',
    residentsNewRequest: '/residentsRequests/new_request',
    residentsPerviousRequests: '/residentsRequests/pervious_requests',
    residentsRequestDetails: '/residentsRequests/request_details/:taskID',
    residentsRequestFeedback: '/residentsRequests/request_feedback/:taskID',
    residentsPayments: '/payments',
    // auth
    login: '/login',
    resetPassword: '/reset-password',
};
